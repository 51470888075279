import { onNextRepaint } from '~/shared/utils/animation';

export const removeReminderCallout = () => {
  const messageCallout = document.querySelector('[data-reminder-callout]');

  if (!messageCallout) return;

  messageCallout.classList.add('reminder-callout--hidden');
  setTimeout(() => {
    messageCallout.innerHTML = '';
  }, 250);
};

export const hasContactInfo = (value) => {
  const regexes = Dribbble?.JsConfig?.engagements?.contact_info_regexes?.map((regex) => new RegExp(regex, 'i')) || [];
  if (!regexes.length) return;

  // eslint-disable-next-line consistent-return
  return regexes.some((regex) => regex.test(value));
};

export const toggleShakeAnimation = (element, shouldShake) => {
  // Remove shake class if present in order to restart animation
  element.classList.remove('animation-shake');

  // Add animation on next repaint to ensure it replays
  if (shouldShake) {
    onNextRepaint(() => {
      element.classList.add('animation-shake');
    });
  }
};

export const toggleContactInfoWarning = (warningElement, isEnabled) => {
  if (!warningElement) return;
  warningElement.classList.toggle('contact-info-warning--visible', isEnabled);
};
